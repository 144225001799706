import React from "react";
import {motion} from "framer-motion";

import Transition from "../../transition";
import Skills from "../../Components/Skills/Skills";

import './About.scss';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload} from "@fortawesome/free-solid-svg-icons";

const About = () => {
    return (
        <motion.section className="About">
            <motion.h2
                initial={{opacity: 0, y: -50}}
                animate={{opacity: 1, y: 0}}
                transition={{delay: 1, duration: 1}}
            >
                <span className={'index'}>1.</span> À propos de moi
            </motion.h2>
            <Skills/>
            <motion.div className={'about-container'}>
                <motion.article
                    className={'about-content'}
                    initial={{opacity: 0, x: -100}}
                    animate={{x: 0, opacity: 1}}
                    transition={{delay: 1.2, duration: 1}}
                >
                    <ul className={'status'}>
                        <li>front-end</li>
                        <li>back-end</li>
                        <li>Mobile</li>
                    </ul>

                    <div className="stats">
                        <div className="stat">
                            <span>1</span>
                            <p>an d'expérience</p>
                        </div>
                        <div className="stat">
                            <span>5</span>
                            <p>années d'étude</p>
                        </div>
                        <div className="stat">
                            <span>10+</span>
                            <p>projets professionnels</p>
                        </div>
                    </div>

                    <div className="text">
                        <p>
                            Je poursuis actuellement ma deuxième année de master Architecte Logiciel, Développeurs d'Applications à l'
                            <a href={'https://etna.io'} target={'_blank'} rel="noopener noreferrer">ETNA</a> en tant qu'apprenti développeur web Fullstack.
                            Cette année succède à une année en alternance en tant que développeur Back-End au sein d'une agence web.
                        </p>
                        <p>
                            Mon intérêt pour le développement web a débuté au lycée, et j'ai choisi de poursuivre dans ce domaine pour ma carrière professionnelle.
                            En plus de mes études, je prends plaisir à partager mes connaissances avec mes amis en leur enseignant les principes fondamentaux du
                            web et du fonctionnement d'Internet.
                        </p>
                    </div>
                    <div className={'hobbies-container'}>
                        <h3>Centres d'intérêts</h3>
                        <div className="hobbies">
                            <div className="hobby">
                                <i className="fa-solid fa-dumbbell"></i>
                                <p>Sport</p>
                            </div>
                            <div className="hobby">
                                <i className="fa-solid fa-gamepad"></i>
                                <p>Jeu</p>
                            </div>
                            <div className="hobby">
                                <i className="fa-solid fa-book-open-reader"></i>
                                <p>Lecture</p>
                            </div>
                        </div>
                    </div>

                </motion.article>

                <motion.article
                    className={'aside'}
                    initial={{opacity: 0, x: 100}}
                    animate={{x: 0, opacity: 1}}
                    transition={{delay: 1.2, duration: 1}}
                >
                    <div className="aside-content">
                        <h3>Informations</h3>
                        <ul className={'aside_social_media'}>
                            <li>
                                <a href="https://github.com/naoufelboukri" target={'_blank'} rel="noopener noreferrer">
                                    <img src={'imgs/github.svg'} alt="Github"/>
                                    <span>Github</span>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.linkedin.com/in/naoufel-boukri-87692917b/" target="_blank" rel="noopener noreferrer">
                                    <img src={'imgs/linkedin.png'} alt="Linkedin"/>
                                    <span>Linkedin</span>
                                </a>
                            </li>
                            <li>
                                <a href="mailto:naoufelboukri@gmail.com">
                                    <img src={'imgs/gmail.png'} alt="Gmail"/>
                                    <span>Gmail</span>
                                </a>
                            </li>
                        </ul>

                        <ul className="aside-resources">
                            <li>
                                <a href="/resources/CV.pdf" target={'_blank'} rel={'noopener noreferrer'}>
                                    <FontAwesomeIcon icon={faDownload} />
                                    <span>CV</span>
                                </a>
                            </li>
                            <li>
                                <a href="/resources/Portfolio.pdf" target={'_blank'} rel={'noopener noreferrer'}>
                                    <FontAwesomeIcon icon={faDownload} />
                                    <span>Portfolio</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </motion.article>
            </motion.div>

            {/*<Controller />*/}
        </motion.section>
    );
}
export default Transition(About);
