import React, {useEffect, useRef} from 'react';
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons'

import Icon from './Icon/Icon';

import './Footer.scss';
import {useLocation} from "react-router-dom";

function Footer() {
    const location = useLocation();
    const ref = useRef<HTMLDivElement>(null);
    useEffect(() => {
        const footer = ref.current;
        if (footer) {
            const footerStyle = footer.style;
            if (location.pathname === '/program') {
                footerStyle.position = 'fixed';
                footerStyle.bottom = '0';
                footerStyle.left = '0';
                footerStyle.top = 'inherit';
            } else {
                footerStyle.position = 'sticky';
                footerStyle.bottom = 'inherit';
                footerStyle.left = 'inherit';
                footerStyle.top = '100%';
            }
        }
    }, [location]);
    return (
        <footer ref={ref}>
            <div className="socialNetwork">
                <Icon icon={faLinkedinIn}></Icon>
            </div>

            <a href="mailto:naoufel@naandev.com">naoufel@naandev.com</a>
        </footer>
    )
}

export default Footer;