import React, {useState, useEffect} from 'react';
import { Link, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';

import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import {delays, durations} from "../../parameters";
import ParticlesBg from "./ParticlesBg/ParticlesBg";
import NavigationSlider from "../NavigationSlider/NavigationSlider";

import './Layout.scss';

function Layout(props: any) {
    // const [showSettings, setShowSettings] = useState(false);
    const [showNavigation, setShowNavigation] = useState(false);
    const location = useLocation();
    const handleActionNavigation = () => {
        setShowNavigation(!showNavigation);
    }

    const variants = {
        default: {},
        light: {
            mixBlendMode: 'difference',
        },
    }

    useEffect(() => {
        setShowNavigation(false);
    }, [location]);

    return (
        // @ts-ignore
        <motion.div className={'Layout'} variants={variants} animate={{mixBlendMode: 'difference'}}>
            {/*<Settings isVisible={showSettings} actionSetting={handleActionSettings}/>*/}
            <NavigationSlider isVisible={showNavigation} actionNavigation={handleActionNavigation}/>
            <motion.header
                initial={{opacity: 0}}
                animate={{opacity: 1}}
                transition={{duration: durations.navbar, delay: delays.navbar}}
            >
                <h1>
                    <Link to='/'><img src="/favicon.ico" alt="NaanDev"/></Link>
                </h1>
                <Navbar actionNavigation={handleActionNavigation}></Navbar>
            </motion.header>
            <main>
                { props.children }
            </main>
            <Footer/>
            <ParticlesBg/>
        </motion.div>
    );
}

export default Layout;