import React, {useEffect, useState} from 'react';
import {Canvas} from "@react-three/fiber";
import {motion} from "framer-motion";

import Presentation from "./Presentation/Presentation";
import Transition from "../../transition";
import Scene from "../../Components/Three/Scene/Scene";
import {delays} from "../../parameters";

import './Home.scss';

const Home = () => {
    const width = window.innerWidth;
    const onInit = window.sessionStorage.getItem('first') === 'yes';
    let delayTitle = onInit ? delays.title+delays.loadPage : delays.title;
    const [mousePosition, setMousePosition] = useState({x: 0, y: 0});
    const [cursorVariant, setCursorVariant] = useState('default');
    const variants = {
        default: {
            x: mousePosition.x - 20,
            y: mousePosition.y - 20,
        },
        click: {
            x: mousePosition.x - 20,
            y: mousePosition.y - 20,
            scale: 1.5,
        },
        titleHovering: {
            x: mousePosition.x - 20,
            y: mousePosition.y - 20,
            scale: 3.5,
            backgroundColor: '#fff',
            mixBlendMode: 'difference'
        },
        buttonHovering: {
            x: mousePosition.x - 20,
            y: mousePosition.y - 20,
            scale: 2,
            backgroundColor: '#fff',
            mixBlendMode: 'difference'
        }
    };
    useEffect(() => {
        const mouseMove = (event: MouseEvent) => setMousePosition({x: event.clientX, y: event.clientY});
        window.addEventListener('mousemove', mouseMove);
        return () => window.removeEventListener('mousemove', mouseMove);
    }, []);
    return (
        <section className='Home'>
            <Presentation OnInitPage={onInit} setIsHover={setCursorVariant}/>
            <motion.div
                className={'canvas-container'}
                initial={{opacity: 0}}
                animate={{opacity:1}}
                transition={{duration: 1, delay: delayTitle + .5}}
            >
                <Canvas style={{width: '100%', height: '100%'}}>
                    <Scene/>
                </Canvas>
            </motion.div>
            { width > 938 &&
                <motion.div
                    className={'cursor'}
                    // @ts-ignore
                    variants={variants}
                    animate={cursorVariant}
                />
            }
        </section>
    );
}
export default Transition(Home);