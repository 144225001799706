import React from 'react';
import { motion } from 'framer-motion';

import {Etude} from "../mock-formations";

import './Card.scss';

interface CardDTO {
    item: Etude,
    index: number,
}
const Card = ({ item, index }: CardDTO) => {
    return (
        <motion.a className="Card" href={'#'+ item.id}
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            transition={{delay:1.5+index*.5, duration: 1}}
        >
            <div className="date">
                <i className="fa-solid fa-calendar-days"></i>
                <span>{item.years}</span>
            </div>

            <h4> {item.degree} {item.name}</h4>

            <p>{item.establishment.name} &bull; {item.establishment.address}</p>
        </motion.a>
    );
}
export default Card;
