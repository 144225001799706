import React from 'react';
import { motion } from 'framer-motion';
import {Link} from "react-router-dom";

import {delays, durations} from '../../../parameters';
import AnimatedTitle from "../../../Components/AnimatedTitle/AnimatedTitle";

import './Presentation.scss';

interface PresentationDTO {
    OnInitPage: boolean,
    setIsHover: (val: string) => void,
}
const Presentation = ({ OnInitPage, setIsHover }: PresentationDTO) => {
    let delayTitle = OnInitPage ? delays.title+delays.loadPage : delays.title;
    const variants = {
        initial: { opacity: 0, x: -100 },
        animate: {
            opacity: 1,
            x: 0,
        },
    }
    return (
        <motion.div className="Presentation"
             variants={variants}
             initial={'initial'}
             animate={'animate'}
             transition={{duration: durations.title, ease: 'easeOut',  delay: delayTitle}}>
                <AnimatedTitle setIsHover={setIsHover}>Naandev.com</AnimatedTitle>

                <div>
                    <p className={'me'}>Je suis <span>développeur web</span></p>
                    <p className={'text'}>Apprenti développeur web Back-end en Master Architecte Logiciel et Développement d'Applications à l'<a href={'https://etna.io/'} target={'_blank'} rel="noopener noreferrer">ETNA</a> à la recherche d'une Alternance.</p>
                </div>

                <motion.div className={'links'}>
                    <motion.button
                        onPointerEnter={() => setIsHover('buttonHovering')}
                        onPointerLeave={() => setIsHover('default')}
                    >
                        <Link to={'/about'}>Get started</Link>
                    </motion.button>
                    <Link to={'/contact'} className={'portfolio-link'}>Me contacter</Link>
                </motion.div>
        </motion.div>
    );
}

export default Presentation;
