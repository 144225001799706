import React, {ReactNode} from 'react';
import './TextInput.scss';

interface TextInputDTO {
    name: string,
    placeholder: string,
    children: ReactNode,
    type?: string,
}

const TextInput = ({ name, placeholder, children, type='text' }: TextInputDTO) => {
    return (
        <div className="TextInput">
            {children}
            <input type={type} name={name} placeholder={placeholder} required/>
        </div>
    );
}
export default TextInput;
