export interface UE {
    title: string,
    modules: string[],
}

interface Establishment {
    name: string;
    address: string;
    ue: UE[];
}

export interface Experience {
    name: string;
    rythme: string;
    address: string;
    job_title: string;
    logo_url: string;
    years: string;
    description: string;
}

export interface Etude {
    id: string;
    degree: string;
    name: string;
    years: string;
    projets: { name: string, link: string }[];
    competences: string[];
    img_url: string;
    establishment: Establishment;
    experience: Experience;
}

const etudes: Etude[] = [
    {
        id: 'dut-2',
        degree: 'DUT',
        name: 'Informatique',
        years: '2019-2021',
        projets: [
            {name: 'Application web de gestion de bibliothèques', link: ''},
            {name: 'Algorithme de recherche de chemin par renforcement', link: ''},
            {name: 'Développement d\'un réseau de neuronne de prédiction d\'arrêts cardiaques', link: ''}
        ],
        competences: ['Programmation Orienté Objet', 'Gestion de bases de données', 'Programmation web'],
        img_url: 'imgs/formation/iut.png',
        establishment: {
            name: "Institut Universitaire de Technologie",
            address: 'Amiens - 80025',
            ue: [{
                title: 'Semestre 3 : Informatique avancée',
                modules: ['Programmation Système', 'Services réseaux', 'Algorithmique avancée', 'Programmation web côté serveur', 'Conception et programmation objets avancées', 'Approfondissements WEB']
            },
                {
                    title: 'Semestre 3 : Cultures scientifique, sociale et humaine avancées',
                    modules: ['Probabilités et statistiques', 'Modélisation mathématiques', 'Droit des TIC', 'Gestion des systèmes d\'information', 'Expression communication - Communication professionnelle', 'Communication professionnelle en anglais']
                },
                {
                    title: 'Semestre 3 : Méthodologie et projets ',
                    modules: ['Méthodologie de la production d\'applications', 'Projet tutoré - Mise en situation professionnelle', 'PPP']
                },
                {
                    title: 'Semestre 4 : Compléments en informatique',
                    modules: ['Administration systèmes et réseaux', 'Programmation répartie', 'Programmation web - client riche', 'Conception et développement d\'applications mobiles', 'Utilisation des CMS', 'Projet tutoré - Compléments']
                },
                {
                    title: 'Semestre 4 : Compléments de cultures scientifique, sociale et humaine',
                    modules: ['Atelier de création d\'entreprise', 'Recherche opérationnelle et aide à la décision', 'Expression-Communication : Communiquer dans les organisations', 'Anglais']
                },],
        },
        experience: {
            name: 'Kallys Technologies',
            rythme: 'Stage',
            address: 'MARLY-LE-ROI 78160, 22, Avenue de l’Europe.',
            job_title: 'Développeur web',
            logo_url: '',
            years: '2021 MAI -JUILLET',
            description: 'Participation à la refonte d\'une application web de gestion de projet et de portefeuilles. L\'application a été repensé puis réalisé en Symfony 4. ',
        },
    },
    {
        id: 'licence-3',
        degree: 'Licence 3',
        name: 'Méthodes Informatiques Appliquées a la Gestion des Entreprises',
        years: '2021-2022',
        projets: [
            {name: 'Application web de gestion de bibliothèques', link: ''},
            {name: 'Algorithme de recherche de chemin par renforcement', link: ''},
            {name: 'Développement d\'un réseau de neuronne de prédiction d\'arrêts cardiaques', link: ''}
        ],
        competences: ['IA', 'Framework back', 'Gestion de projet'],
        img_url: 'imgs/formation/ufr.png',
        establishment: {
            name: "UFR des sciences",
            address: 'Amiens - 80000',
            ue: [{
                title: 'Semestre 5',
                modules: ['Langages formels', 'Méthodes d\'Analyse et de Conception de Systèmes d\'Information (MACSI)', 'Modelisation Objet', 'Programmation Objet 2', 'Théories des Systèmes d\'Exploitation', 'Framework WEB']
            },
                {
                    title: 'Semestre 6',
                    modules: ['Projet Informatique : QLearning - Recherche de chemin par renforcement', 'Réseau', 'Intelligence Artificielle', 'XML']
                },
                {
                    title: 'Enseignements généraux',
                    modules: ['Mathématiques financières', 'Système d\'informations financières', 'Anglais', 'PIX', 'Système d\'information de gestion']
                }],
        },
        experience: {
            name: 'Iteracode',
            rythme: 'Stage',
            address: 'AMIENS 80000 -  53 Rue de l\'Amiral Courbet',
            job_title: 'Coordinateur de projet',
            logo_url: '',
            years: '2022 AVRIL - JUIN',
            description: 'Suivi des projets de l\'entreprise, participation et animation des démonstrations client, saisi des besoins, rédaction les rapports d\'erreur, réalisation des livrables de conception et direction des équipes pour les nouvelles fonctionnalités à mettre en place. ',
        },
    },
    {
        id: 'master-1',
        degree: 'Master Of Science 1',
        name: 'Architecte Logiciel, Développeur d\'Applications',
        years: '2022-2023',
        projets: [
            {name: 'Développement d\'un blog de jeux vidéos', link: ''},
            {name: 'Développement d\'une application mobile Pictionary en ligne', link: ''},
            {name: 'Développement d\'un jeu de parcours sur Unity', link: ''}
        ],
        competences: ['Api rest', 'frameworks front', 'jeux vidéo'],
        img_url: 'imgs/formation/etna.png',
        establishment: {
            name: "École des Technologies Numériques Avancées",
            address: 'Ivry-sur-Seine - 94200',
            ue: [
                {
                    title: 'Piscine',
                    modules: ['Fondamentaux de l’administration conteneurisée', 'Fondamentaux de la programmation orientée objet']
                },
                {
                    title: 'Tronc commun informatique',
                    modules: ['Architecture de Base de Données', 'Expertise Blockchain'],
                },
                {
                    title: 'Enseignements généraux',
                    modules: ['Expression et communication en langue anglaise', 'Intelligence économique : analyse', 'Marketing : méthode Lean startup', 'Méthodologie de conduite de projet'],
                },
                {
                    title: 'Enseignements professionnalisations',
                    modules: ['Validation des acquis entreprise', 'Grand Projet ETNA (GPE) : prouver la viabilité de votre projet libre', 'Master Code Camp - 1 semaine pour répondre à un défi qui vous permet d’aborder les enjeux cruciaux de votre ingénierie métier', 'Méthodologie de conduite de projet', 'Innov’Camp : Développer et marketer un projet innovant dans le cadre de votre ingénierie métier'
                    ],
                },
                {
                    title: 'Enseignements de spécialité',
                    modules: ['Programmation avancée en C++', 'Développement de l\'API Rest', 'Programmation Mobile avancée', 'Qualité et optimisation du code', 'Algorithmique avancée : machine learning'],
                }
            ],
        },
        experience: {
            name: 'TKT',
            rythme: 'Alternance',
            address: 'PARIS 75019, 8 rue de Nantes',
            job_title: 'Développeur BACK-END',
            logo_url: '',
            years: '03/10/2022 - 13/12/2023',
            description: '\n' +
                '\n' +
                'Travail sur différents projet métier, réalisation des différentes missions demandées avec le suivi de mon tuteur.\n' +
                '\n' +
                'Utilisation des frameworks PHP Laravel et Symfony.\n',
        },
    },
]

export {etudes};
