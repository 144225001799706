import React, {useState} from 'react';
import {motion} from "framer-motion";

import './Skill.scss';

interface SkillDTO {
    skill: { name: string, filename: string },
    index: number
}

const Skill = ({skill, index}: SkillDTO) => {
    const defaultPath: string = '/imgs/skills/';
    const variantsImgs = {
        visible: { opacity: 1, x: 0, transition: { duration: .15, delay: .5 + index*.25 }},
        hidden: { opacity : 0, x: -100 },
    };
    const variantsSpan = {
        'hidden': {opacity: 0},
        'visible': {opacity: 1},
    }
    const [isHover, setIsHover] = useState(false);
    const handleHoverImgStart = () => setIsHover(true);
    const handleHoverImgEnd = () => setIsHover(false);
    return (
        <motion.div
            className={'Skill'}
            onHoverStart={handleHoverImgStart}
            onHoverEnd={handleHoverImgEnd}
        >
            <motion.img
                src={defaultPath+skill.filename} alt={skill.name}
                variants={variantsImgs}
                initial={'hidden'}
                animate={'visible'}
                transition={{duration: .6}}
            />
            <motion.span
                variants={variantsSpan}
                initial={'hidden'}
                animate={isHover ? 'visible' : 'hidden'}
                transition={{duration: .5}}
            >
                {skill.name}
            </motion.span>
        </motion.div>
    );
}
export default Skill;
