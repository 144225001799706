import React, {useEffect} from 'react';
import { motion } from 'framer-motion';

import {Projet, Technology} from "../mock-projets";

import './ProjectCard.scss';

interface ProjectCardDTO {
    projet: Projet,
    index: number,
}

const ProjectCard = ({projet, index}: ProjectCardDTO) => {
    const imgsPath: string = 'imgs/projects/'+projet.name.toLowerCase()+'/';
    const technologies = projet.technologies.map((technologie: Technology) => {
        let backgroundColor: string;
        if (technologie.type === 'front') {
            backgroundColor = 'rgba(96, 196, 84, .5)';
        } else if (technologie.type === 'back') {
            backgroundColor = 'rgba(214, 168, 41, .5)';
        } else {
            backgroundColor = '#c39e05';
        }
        return (
            <li style={{backgroundColor: backgroundColor }} key={technologie.name}>{technologie.name}</li>
        );
    });

    useEffect(() => {

        const projectCardClickHandler = () => {
            window.location.href = '#'+projet.name;
        }

        const projectCard = document.getElementById('projectCard-'+projet.name);
        if (projectCard) {
            projectCard.addEventListener('click', projectCardClickHandler);
        }
    });
    return (
        <motion.div className="ProjectCard" style={{backgroundColor: projet.bgColor}} id={'projectCard-'+projet.name}
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            transition={{delay:1.5+index*.2, duration: 1}}
        >
            <img src={imgsPath+'default.png'} alt={projet.name}/>

            <div className="ProjectCard_content">
                <h3>{projet.name}</h3>
                <p>{projet.description.slice(0,80)} {projet.description.length > 80 && '...'} </p>
            </div>

            <ul className="technologies">
                {technologies}
            </ul>
        </motion.div>
    );
}
export default ProjectCard;
