import React from 'react';
import {OrbitControls} from "@react-three/drei";
import {MotionConfig} from "framer-motion";
import {motion} from "framer-motion-3d";

import Sphere from "../Geometry/Sphere";

import './Scene.scss';

const Scene = () => {
    return (
        <MotionConfig transition={{bounce: .6}}>
            <directionalLight position={[0,1,2]}/>
            <motion.group>
                <Sphere/>
            </motion.group>
            <OrbitControls
                enableZoom={false}
            />
        </MotionConfig>
    );
}
export default Scene;
