import React, {useEffect, useRef} from 'react';
import {motion, useAnimation, useInView} from "framer-motion";

import mockSkills from "./mock-skills";
import Skill from './Skill/Skill';

import './Skills.scss';

const Skills = () => {
    const controls = useAnimation();
    const controlsImg = useAnimation();
    const ref = useRef(null);
    const isInView = useInView(ref);
    const variants = {
        visible: { opacity: 1, transition: { duration: 1, delay: 1 }},
        hidden: { opacity : 0 },
    };
    useEffect(() => {
        if (isInView) {
            controls.start('visible');
            controlsImg.start('visible');
        }
    }, [controls, controlsImg, isInView]);
    const mappedSkills = mockSkills.map((skill: {name: string, filename: string}, index) =>
        <Skill skill={skill} key={index} index={index}/>
    );

    return (
        <motion.div
            ref={ref}
            className="Skills"
            variants={variants}
            initial={'hidden'}
            animate={controls}
        >
            {mappedSkills}
        </motion.div>
    );
}
export default Skills;
