import React from 'react';
import { motion } from 'framer-motion';

import './AnimatedTitle.scss';

interface AnimatedTitleDTO {
    children: string,
    setIsHover: (val: string) => void,
}
const AnimatedTitle = ({ children, setIsHover }: AnimatedTitleDTO) => {

    const width = window.innerWidth;
    const getSize = () => {
        if (width < 724 ) {
            return 3;
        } else if (width >= 724 && width < 1240) {
            return 4;
        } else {
            return 6;
        }
    }

    const title = children.split('').map((letter: string, index) => {
        const color = '#' + (Math.random() * 0xfffff * 1000000).toString(16).slice(0,6);
        return (
            <motion.span
                className="letter"
                initial={{fontSize: getSize()+'rem', color: '#fff'}}
                whileHover={{fontSize: (getSize()+2)+'rem', color: color}}
                transition={{duration: .5}}
                key={index}
            >
                {letter}
            </motion.span>
        );
    });
    return (
        <motion.h1
            className={'AnimatedTitle'}
            onPointerEnter={() => setIsHover('titleHovering')}
            onPointerLeave={() => setIsHover('default')}
        >
            {title}
        </motion.h1>
    );
}
export default AnimatedTitle;
