import React, {useState} from 'react';

import './Select.scss';

interface SelectDTO {
    choices: string[],
    name: string,
}

const Select = ({ choices, name }: SelectDTO) => {

    const [selectedValue, setSelectedValue] = useState('Autre');

    const choices_mapped = choices.map((choice: string, index) => {
        const value = choice.toLowerCase();

        return (
            <div className={'radio-button'} key={index} onClick={() => setSelectedValue(choice)}>
                <input type={'radio'} name={name} id={value} value={value} required checked={selectedValue === choice}/>
                <label htmlFor={value}>{choice}</label>
            </div>
        );
    })

    return (
        <div className="Select">
            {choices_mapped}
        </div>
    );
}
export default Select;
