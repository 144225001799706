import React from 'react';
import {Link, useLocation} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import {motion} from "framer-motion";

import './Navbar.scss';

interface NavbarDTO {
//     actionSettings: () => void,
    actionNavigation: () => void,
}

export interface NavLink {
    link: string,
    name: string,
}

const navLinks: NavLink[] = [
    { link: 'about', name: 'à propos' },
    { link: 'projects', name: 'Projets' },
    { link: 'program', name: 'Formations' },
];

function Navbar ({actionNavigation}: NavbarDTO) {
    const location = useLocation();
    const links = navLinks.map((link: NavLink, index: number) => {
        const className = location.pathname === '/'+link.link ? 'link active' : 'link';
        return (
            <li key={index}><Link to={'/'+link.link} className={className}>{link.name}</Link></li>
        );
    })

    return (
        <nav>
            <ul>
                {links}
            </ul>
            <Link to={'/contact'} className={'contact'}>
                <motion.span whileHover={{scale: 1.2}}>Me contacter</motion.span>
            </Link>
            {/*<button onClick={actionSettings}>*/}
            {/*    <i className="fa-solid fa-gear"></i>*/}
            {/*</button>*/}
            <button id='navButton' onClick={actionNavigation}><FontAwesomeIcon icon={faBars}/></button>
        </nav>
    )
}

export {navLinks};
export default Navbar;

