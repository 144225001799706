import React, {useEffect, useRef} from 'react';
import {motion, useAnimation, useInView} from "framer-motion";
import {Link} from 'react-router-dom';

import {Etude} from "../mock-formations";
import CardExperience from "./CardExperience/CardExperience";

import './ProgramArticle.scss';

interface ProgramArticleDTO {
    item: Etude
}

const ProgramArticle = ({item}: ProgramArticleDTO) => {

    const competences = item.competences.map((competence: string) => <li>{competence}</li>);
    const projects = item.projets.map((projet: {name: string, link: string}) => <li><Link to={'/projects'}>{projet.name}</Link></li>);

    const ref = useRef(null);
    const refInView = useInView(ref);
    const controls = useAnimation();

    const variants = {
        visible: { opacity: 1, transition: { duration: 1 } },
        hidden: { opacity: 0 },
    }

    useEffect(() => {
        if (refInView) {
            controls.start('visible');
        } else {
            controls.start('hidden');
        }
    }, [controls, refInView]);

    return (
        <motion.article className="ProgramArticle scrollPage" id={item.id} ref={ref}
            initial={'hidden'}
            variants={variants}
            animate={controls}
        >
            <div className="Program-container ProgramArticle_container">

                <div className="ProgramArticle_content_formation">
                    <div className="header">
                        <span className="years">{item.years}</span>
                        <div className="header_img_container">
                            <img src={item.img_url} alt={item.establishment.name}/>
                        </div>
                        <div className="header_title">
                            <span>{item.establishment.address} &bull; {item.establishment.name}</span>
                            <h3>{item.degree}</h3>
                            <h4>{item.name}</h4>
                        </div>
                    </div>
                    <hr/>

                    <ul className="competences">
                        {competences}
                    </ul>
                    <div className="projets">
                        <h5>Quelques projets :</h5>
                        <ul>
                            {projects}
                        </ul>
                    </div>
                </div>

                <CardExperience experience={item.experience} parentId={item.id}/>

            </div>
        </motion.article>
    );
}
export default ProgramArticle;
