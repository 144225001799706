import React, {useEffect} from 'react';
import {Route, Routes, useLocation} from 'react-router-dom';
import {AnimatePresence} from "framer-motion";
import { motion } from 'framer-motion';

import Home from '../Pages/Home/Home';
import About from "../Pages/About/About";
import Layout from '../Containers/Layout/Layout';
import {delays, durations} from '../parameters';
import spinner from '../Svgs/spinner.svg';
import './App.scss';
import Program from "../Pages/Program/Program";
import Projets from "../Pages/Projets/Projets";
import Contact from "../Pages/Contact/Contact";

function App() {
    const location = useLocation();

    useEffect(() => {
        window.sessionStorage.setItem('first', 'no');
    }, [location.pathname]);

    window.sessionStorage.setItem('first', 'yes');

    return (
        <Layout>
            <motion.div
                className={'load-page'}
                initial={{scaleY: 1}}
                animate={{scaleY: -1}}
                transition={{ duration: durations.loadPage, delay: delays.loadPage, easeOut: 1 }}
            >
                <div>
                    <motion.img
                        initial={{opacity: 1}}
                        animate={{opacity: 0}}
                        transition={{delay: delays.loadPage*0.8}}
                        src={spinner} alt={'timer'}/>
                </div>
            </motion.div>
            <AnimatePresence mode={'wait'}>
                <Routes location={location} key={location.pathname}>
                    <Route index element={<Home/>}/>
                    <Route path={'about'} element={<About/>}/>
                    <Route path={'program'} element={<Program/>}/>
                    <Route path={'projects'} element={<Projets/>}/>
                    <Route path={'contact'} element={<Contact/>}/>
                </Routes>
            </AnimatePresence>
        </Layout>
    );
}

export default App;
