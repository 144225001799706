import React, {ReactNode} from 'react';
import './Field.scss';

interface FieldDTO {
    label: string,
    children: ReactNode
}

const Field = ({label, children}: FieldDTO) => {
    return (
        <div className="Field">
            <label htmlFor="">{label}</label>
            {children}
        </div>
    );
}
export default Field;
