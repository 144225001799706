import React, {useEffect, useState} from 'react';

import {Projet, Technology} from "../mock-projets";

import './ProjectBanner.scss';

interface ProjectBannerDTO {
    projet: Projet
}

const ProjectBanner = ({projet}: ProjectBannerDTO) => {
    const imgPath: string = 'imgs/projects/'+projet.name.toLowerCase()+'/';
    const imgLink: string = `url('${imgPath}bg_banner.jpg')`;
    const linearGradient: string = 'linear-gradient('+ projet.bgColor +'DD, '+ projet.bgColor +'AA, rgba(0, 0, 0, .5))';
    const background = linearGradient+','+imgLink;

    const [isActive, setIsActive] = useState(false);
    const ProjectBannerClassName = isActive || window.location.hash === '#'+projet.name ? 'ProjectBanner ProjectBanner-active' : 'ProjectBanner';
    let buttonClassName = isActive ? 'ProjectBanner-button-active' : '';
    if (window.location.hash === '#'+projet.name) {
        buttonClassName += ' btnDisabled';
    }
    const images = projet.imgs.map((imgName: string, index: number) => <img src={imgPath+imgName} alt={"image"+index} key={imgName}/>);
    const technologies = projet.technologies.map((technologie: Technology) => {
        return (
            <li>{technologie.name}</li>
        )
    })
    useEffect(() => {
        const button = document.getElementById('ProjectBanner-button-'+projet.name);
        const banner = document.getElementById(projet.name);

        const handleButtonClick = () => {
            if (banner && button) {
                if (!isActive) {
                    button.innerText = 'FERMER';
                } else {
                    button.innerText = 'APERCU';
                }
                setIsActive(!isActive);
            }
        }

        if (button) {
            button.addEventListener('click', handleButtonClick);
        }
    })

    return (
    <div className={ProjectBannerClassName} id={projet.name} style={{backgroundImage: background}}>

        <div className='ProjectBanner-container ProjectBanner-description'>
            <h3>{projet.name}</h3>
            <div className='ProjectBanner-content'>
                <ul>
                    {technologies}
                </ul>
                <p>{projet.description}</p>
                {projet.git &&
                    <a href={projet.git} target={'_blank'} className="git" rel="noopener noreferrer">
                        <img src="imgs/github.svg" alt="Github"/>
                    </a>
                }
            </div>
        </div>

        <div className='ProjectBanner-container ProjectBanner-slider'>
            {images}
        </div>

        { projet.details &&
            <button id={'ProjectBanner-button-' + projet.name} className={buttonClassName}>Aperçu</button>
        }
    </div>
    );
}
export default ProjectBanner;
