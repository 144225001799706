import React, {useRef, useState} from 'react';
import emailjs from '@emailjs/browser';
import {faAddressBook, faEnvelope, faPhone, faCircleCheck, faCircleXmark} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {motion} from "framer-motion";

import Field from "./Field/Field";
import TextInput from "./Inputs/TextInput/TextInput";
import Transition from "../../transition";
import Select from "./Inputs/Select/Select";
import spinner from '../../Svgs/spinner.svg';

import './Contact.scss';

const Contact = () => {

    const formRef = useRef(null);

    const [alertSuccessIsActive, setAlertSuccessIsActive] = useState(false);
    const [alertErrorIsActive, setAlertErrorIsActive] = useState(false);
    const [isSent, setIsSent] = useState(false);

    const [loading, setLoading] = useState(false);

    const sendEmail = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setLoading(true);
        const mailServiceId = process.env.REACT_APP_MAIL_SERVICE_ID;
        const templateServiceId = process.env.REACT_APP_MAIL_TEMPLATE_ID;
        const publicKey = process.env.REACT_APP_MAIL_PUBLIC_KEY;

        if (formRef.current && mailServiceId && templateServiceId && publicKey && !isSent) {
            emailjs.sendForm(mailServiceId, templateServiceId, formRef.current, publicKey)
            .then((result) => {
                mailSuccess();
                setLoading(false);
            }, (error) => {
                mailFailed();
                setLoading(false);
            });
        } else {
            mailFailed();
            setLoading(false)
        }
    }

    const mailSuccess = () => {
        setAlertSuccessIsActive(true);
        setIsSent(true);
        setTimeout(() => {
            setAlertSuccessIsActive(false);
        }, 3000);
    }

    const mailFailed = () => {
        setAlertErrorIsActive(true);
        setTimeout(() => {
            setAlertErrorIsActive(false);
        }, 3000);
    }

    return (
        <div className="Contact">

            <div className={alertSuccessIsActive ? 'alert alert-success alert-active' : 'alert alert-success'}>
                <FontAwesomeIcon icon={faCircleCheck} />
                <p>L'email a été envoyé avec succès !</p>
            </div>
            <div className={alertErrorIsActive ? 'alert alert-failed alert-active' : 'alert alert-failed'}>
                <FontAwesomeIcon icon={faCircleXmark} />
                <p>Une erreur s'est produite</p>
            </div>

            <motion.div className='Contact-container'>
                <div className='Contact-module'>
                    <h2>
                        N'attendez plus,
                        <br/>
                        <span>contactez-moi</span>
                    </h2>
                    <div className='Contact-module-details'>
                        <div className='Contact-module-detail'>
                            <FontAwesomeIcon icon={faPhone} className={'icon'}/>
                            <a href="tel:0782228290">(+33) 07 82 22 82 90</a>
                        </div>
                        <div className='Contact-module-detail'>
                            <FontAwesomeIcon icon={faEnvelope} className={'icon'}/>
                            <a href="mailto:naoufel@naandev.com">naoufel@naandev.com</a>
                        </div>
                    </div>
                </div>

                <div className='formContainer'>
                    <form ref={formRef} onSubmit={sendEmail}>
                        <h3>Contact</h3>
                        <Field label='Vous êtes :'>
                            <Select choices={['Client', 'Recruteur', 'Autre']} name='customer'/>
                        </Field>
                        <Field label='Votre nom'>
                            <TextInput name={'from_name'} placeholder={'Nom'}>
                                 <FontAwesomeIcon icon={faAddressBook} className={'icon'}/>
                             </TextInput>
                        </Field>
                        <Field label='Votre email'>
                            <TextInput name={'email'} placeholder={'Email'}>
                                 <FontAwesomeIcon icon={faEnvelope} className={'icon'}/>
                             </TextInput>
                        </Field>
                        <Field label='Votre téléphone'>
                            <TextInput name={'tel'} placeholder={'Téléphone'}>
                                 <FontAwesomeIcon icon={faPhone} className={'icon'}/>
                             </TextInput>
                        </Field>
                        <Field label={'Votre message *'}>
                            <textarea name="message" id="message" placeholder={'Taper votre message ici'} required></textarea>
                        </Field>

                        <button type='submit'>
                            <img src={spinner} className={loading ? 'loading' : ''}/>
                            <span>Envoyer</span>
                        </button>
                    </form>
                </div>
            </motion.div>
        </div>
    );
}
export default Transition(Contact);
