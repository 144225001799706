const delays = {
    loadPage: 3,
    navbar: 1,
    title: 1.2,
};

delays.navbar += delays.loadPage;
// delays.title += delays.loadPage;

const durations = {
    loadPage: .5,
    navbar: 1.5,
    title: 2
}

export { delays, durations };