import React, {useEffect, useRef, useState} from 'react';
import {motion} from 'framer-motion-3d';
import {Mesh} from "three";
import {useFrame} from "@react-three/fiber";
import {Leva, useControls} from "leva";

const Sphere = () => {
    const ref = useRef<Mesh>();
    const [isPress, setIsPress] = useState(false);
    const { x, y, z } = useControls({x: 0, y: .4, z: .2});

    useFrame((state, delta, frame) => {
        const sphereRef = ref.current;
        if (sphereRef) {
            sphereRef.rotation.x += delta*x;
            sphereRef.rotation.y += delta*y;
            sphereRef.rotation.z += delta*z;
        }
    });

    useEffect(() => {
        console.log('pressed');
    }, [isPress]);

    const sizeByWidth = () => {
        const width = window.innerWidth;
        if (width >= 1240) {
            return 0;
        } else {
            return -1;
        }
    }

    return (
        <motion.mesh
            // @ts-ignore
            ref={ref}
            position={[0,0,sizeByWidth()]}
            rotation={[.5, .5, -.5]}
            onClick={() => setIsPress(true)}
            whileHover={{scale: 1.1, rotateX: 1}}
        >
            <Leva hidden/>
            <motion.sphereGeometry args={[ 1.75, 25, 25]}/>
            <motion.meshStandardMaterial wireframe color={'#2596b9'}/>
        </motion.mesh>
    );
}
export default Sphere;
