export interface Technology {
    type: string;
    name: string;
}
export interface Projet {
    name: string;
    description: string;
    technologies: Technology[];
    git?: string;
    imgs: string[];
    bgColor: string;
    details: boolean;
}

const projects: Projet[] = [
    {
        name: 'GameBlog',
        description: 'Ce blog permet aux utilisateurs ayant un compte de personnaliser son profil, créer des articles et d’interagir avec les articles des autres utilisateurs en laissant un avis et une note.',
        technologies: [{ type: 'front', name: 'AngularJS'}, { type: 'back', name: '.NET Code'}],
        git: 'https://github.com/naoufelboukri/api_rest',
        imgs: ['grp_gameblog.png'],
        bgColor: '#283180',
        details: true,
    },
    {
        name: 'MrBooks',
        description: 'Réalisation en équipe de 4 d\'un site de gestion de bibliothèque répondant aux fonctionnalités demandées par les professeurs responsables.',
        technologies: [{ type: 'back', name: 'Symfony'}],
        git: 'https://gitlab.com/naoufelboukri/bookland',
        imgs: ['grp_mrbooks.png'],
        bgColor: '#d3773c',
        details: true,
    },
    {
        name: 'Amethyst',
        description: 'Première application développée en React Native permettant la prise de notes en Markdown partagée entre utilisateurs.',
        technologies: [{ type: 'front', name: 'React Native'}, { type: 'back', name: 'NestJS'}],
        git: 'https://rendu-git.etna-alternance.net/module-9267/activity-50499/group-999006',
        imgs: ['grp_amethyst.png'],
        bgColor: '#373737',
        details: true,
    },
    {
        name: 'GoalRush',
        description: 'Application de création d\'effectif de football et organisation de match en ligne.',
        technologies: [{ type: 'front', name: 'React Native'}, { type: 'back', name: 'NestJS'}],
        git: 'https://github.com/naoufelboukri/goalrush',
        imgs: ['grp_goalrush.png'],
        bgColor: '#444465',
        details: true,
    },
    {
        name: 'Cisway',
        description: 'Initiation au développement avec NodeJs et Angular sur une application de gestion et vente de produits par utilisateur.',
        technologies: [{ type: 'front', name: 'AngularJS'}, { type: 'back', name: 'NodeJS'}],
        git: '',
        imgs: [],
        bgColor: '#89c1bf',
        details: false,
    },
    {
        name: 'FestiMusic',
        description: 'Réalisation d\'un site de candidatures pour un festival de musique avec le mini framework FlightPHP et le moteur de templates smarty.',
        technologies: [{ type: 'front', name: 'Smarty'}, { type: 'back', name: 'FlightPHP'}],
        git: 'https://gitlab.com/naoufelboukri/projet-festival',
        imgs: [],
        bgColor: '#6e68c5',
        details: false,
    },
    {
        name: 'ERIS',
        description: 'Participation au développement d\'une application métier d\'administration de documents en ligne pour les grandes organisations.',
        technologies: [{ type: 'front', name: 'React'}, { type: 'back', name: 'NestJS'}],
        imgs: [],
        bgColor: '#ffffff',
        details: false,
    },
];

export default projects;