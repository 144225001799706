import React from 'react';
import {motion} from "framer-motion";

import {Etude, etudes} from "./mock-formations";
import Card from "./Card/Card";
import ProgramArticle from "./ProgramArticle/ProgramArticle";
import transition from "../../transition";

import './Program.scss';

const Program = () => {

    const program_cards = etudes.map((etude: Etude, index: number) => <Card item={etude} index={index}/>);
    const articles = etudes.map((etude: Etude) => <ProgramArticle item={etude}/>);
    return (
        <section className={'Program'}>
            <div className="Program-scroll">
                <div className="Program-index scrollPage">
                    <div className="Program-container">
                        <motion.h2
                            initial={{opacity: 0, y: -50}}
                            animate={{opacity: 1, y: 0}}
                            transition={{delay: 1, duration: 1}}
                        >
                            <span className={'index'}>3.</span> Formations - Expériences
                        </motion.h2>

                        <div className="Program-index-items">
                            { program_cards }
                        </div>
                    </div>
                </div>

                {articles}
            </div>
        </section>
    );
}

export default transition(Program);