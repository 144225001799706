import React from 'react';
import {Link} from 'react-router-dom';
import {motion} from "framer-motion";

import Transition from "../../transition";
import ProjectCard from "./ProjectCard/ProjectCard";
import ProjectBanner from "./ProjectBanner/ProjectBanner";
import mockProjets, {Projet} from "./mock-projets";

import './Projets.scss';


const Projets = () => {

    const projets = mockProjets.map((projet: Projet, index: number) => <ProjectCard projet={projet} index={index}/>)
    const projectBanners = mockProjets.map((projet: Projet) => <ProjectBanner projet={projet}/>);
    return (
        <section className={'Projets'}>
            <div className="container">
                <motion.h2
                    initial={{opacity: 0, y: -50}}
                    animate={{opacity: 1, y: 0}}
                    transition={{delay: 1, duration: 1}}
                >
                    <span className={'index'}>2.</span> Projets
                </motion.h2>

                <div className="Projets-index">
                    {projets}
                    <motion.div className="YourProjectCard"
                        initial={{opacity: 0}}
                        animate={{opacity: 1}}
                        transition={{delay:1.5+projets.length*.2, duration: 1}}
                    >
                        <Link to='/contact'><span>+</span></Link>
                    </motion.div>
                </div>
            </div>
            <div className="Project-banners">
                {projectBanners}
            </div>
        </section>
    );
}

export default Transition(Projets);