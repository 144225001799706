const mockSkills = [
    {name: 'HTML', filename: 'html.png'},
    {name: 'CSS', filename: 'css.png'},
    {name: 'Javascript', filename: 'javascript.png'},
    {name: 'Typescript', filename: 'typescript.png'},
    {name: 'SASS', filename: 'scss.png'},
    {name: 'Python', filename: 'python.png'},
    {name: 'CSharp', filename: 'cs.png'},
    {name: 'Symfony', filename: 'symfony.png'},
    {name: 'Laravel', filename: 'laravel.png'},
    {name: 'React', filename: 'react.png'},
    {name: 'Angular', filename: 'angular.png'},
    {name: 'Wordpress', filename: 'wordpress.png'},
]

export default mockSkills;