import React from 'react';
import {Link, useLocation} from 'react-router-dom';

import { navLinks, NavLink } from '../Navbar/Navbar';
import './NavigationSlider.scss';

interface NavigationSliderDTO {
    isVisible: boolean,
    actionNavigation: () => void
}

const NavigationSlider = ({ isVisible, actionNavigation }: NavigationSliderDTO) => {

    const className = isVisible ? 'NavigationSlider NavigationSliderActive' : 'NavigationSlider';
    const location = useLocation();

    const links = navLinks.map((link: NavLink, index: number) => {
        const linkClassName = location.pathname === '/'+link.link ? 'link activeLink' : 'link';
        return (
            <li key={index}>
                <Link to={'/'+link.link} className={linkClassName}>{link.name.toUpperCase()}</Link>
            </li>
        );
    });

    return (
        <div className={className}>
            <ul className={'links'}>
                { links }
            </ul>
        </div>
    );
}
export default NavigationSlider;
