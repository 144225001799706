import React, {useState} from 'react';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {Experience} from "../../mock-formations";

import './CardExperience.scss';


interface CardExperienceDTO {
    experience: Experience;
    parentId: string;
}

const CardExperience = ({experience, parentId}: CardExperienceDTO) => {

    const [isUnrolled, setIsUnrolled] = useState(true);
    const chevronClickHandler = () => {
        const CardExperienceContainer = document.querySelector('#'+parentId+' .CardExperience_container') as HTMLDivElement;
        const CardExperienceChevron = document.querySelector('#'+parentId+' .CardExperience_footer_chevron') as HTMLElement;

        if (CardExperienceContainer !== null && CardExperienceChevron !== null) {
            if (isUnrolled) {
                CardExperienceContainer.style.maxHeight = CardExperienceContainer.scrollHeight+'px';
                CardExperienceChevron.style.transform = 'rotate(180deg)';
            } else {
                CardExperienceContainer.style.maxHeight = '0';
                CardExperienceChevron.style.transform = 'rotate(0deg)';
            }
            setIsUnrolled(!isUnrolled);
        }
    }

    return (
        <div className='CardExperience'>
            <h5>Expérience</h5>
            <hr/>

            <div className="CardExperience_title">
                <h6>{experience.name} <span>({experience.rythme})</span></h6>
                <span>{experience.address}</span>
            </div>
            {/*TODO : Peut etre mettre cardexperience title a la place de experience et mettre experience au dessus de la cardexperience ? */}
            <div className="CardExperience_container">
                <div className="CardExperience_content">
                    <div className='CardExperience_information'>
                        <span className={'label'}>Poste : </span>
                        <span className={'value'}>{experience.job_title}</span>
                    </div>
                    <div className='CardExperience_information'>
                        <span className={'label'}>Date : </span>
                        <span className={'value'}>{experience.years}</span>
                    </div>
                    <div className='CardExperience_information'>
                        <span className={'label'}>Description : </span>
                        <span className={'value'}>{experience.description}</span>
                    </div>
                </div>
            </div>
            <div className="CardExperience_footer" onClick={chevronClickHandler}>
                <FontAwesomeIcon icon={faChevronDown} className={'CardExperience_footer_chevron'}/>
            </div>
        </div>
    );
}
export default CardExperience;
